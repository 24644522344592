import { fetchExerciseSetsByWorkoutId } from "./firebase/services/workoutService";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateTeamImageURL } from "./firebase/services/teamService";
import { updateUserProfileWithImage } from "./firebase/services/userService";
import { storage } from "./firebase/firebase_config";
import { getAuth } from 'firebase/auth';
import { Workout } from "./models";

const getCurrentUserId = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) return null;
  return user.uid;
}

const getCurrentUserEmail = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) return null;
  return user.email;
}

const handleFileChange = async (e: any, teamId: string | null, userId: string | null) => {
  if (!teamId && !userId) return;

  try {
    const imageFile = e.target.files[0];
    const invalidFileError = validateImageFile(imageFile);

    if (invalidFileError) throw new Error(invalidFileError);

    const imageURL = await uploadImage(imageFile, teamId ? `team_${teamId}` : `user_${userId}`, teamId ? "team" : "user");
    if (imageURL) {
      
      let updatedImage;
      
      if (teamId) updatedImage = await updateTeamImageURL(teamId, imageURL)
      else if (userId) updatedImage = await updateUserProfileWithImage(userId, imageURL);
      else throw new Error("Failed to upload image. Please try again.");

      console.log("Updated image: ", updatedImage);

      if (!updatedImage) throw new Error("Failed to upload image. Please try again.");
      
      return imageURL;
    } else throw new Error("Failed to upload image. Please try again.");
  } catch (error) {
    console.error("Failed to upload image", error);
    throw new Error("Failed to upload image. Please try again.");
  }
};

const uploadImage = async (file: File, fileName: string, type: "team" | "user"): Promise<string> => {
  const filePath = type === "team" 
    ? `team_images/${fileName}`
    : `profile_pictures/${fileName}`;

  const storageRef = ref(storage, filePath);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

const validateImageFile = (imageFile: File) => {
  const validTypes = ['image/jpeg', 'image/png'];

  if (!imageFile) return "Please upload a file.";
  else if (!validTypes.includes(imageFile.type)) return "Please upload a file in jpg or png format.";
  else if (imageFile.size > (5 * 1024 * 1024)) return"Please upload a file smaller than 5MB."
  else return null;
}

const getValueByMovementType = (rep: any, variable: string, movementType: string) => {
  try {
    if (movementType === "Concentric") {
      if (!rep.concentricMovement) return Number(rep.eccentricMovement[variable].toPrecision(3));
      return Number(rep.concentricMovement[variable].toPrecision(3));
    } else if (movementType === "Eccentric") {
      if (!rep.eccentricMovement) return Number(rep.concentricMovement[variable].toPrecision(3));
      return Number(rep.eccentricMovement[variable].toPrecision(3));
    } else {
      return Number(((rep.concentricMovement[variable] + rep.eccentricMovement[variable]) / 2).toPrecision(3));
    }
  } catch (error) {
    console.error('Failed to fetch value for rep variable: ', error, rep, variable, movementType);
    return 0;
  }
}

const getSetsForWorkout = async (workouts: Workout[], includeAverages: boolean, movementType: string) => {
  let formattedWorkouts = [];

  for (let workout of workouts) {
    let workoutSets = await fetchExerciseSetsByWorkoutId(workout.refId);
    //console.log(workoutSets)
    if (!workoutSets) continue;

    let exercise = { ...workoutSets[0] };
    exercise.Reps = [];  
    
    let totalVelocity = 0;
    let totalPower = 0;
    let totalForce = 0;
    let numberOfReps = 0;
    
    for (let set of workoutSets) {
      if (!set.Reps) continue;
      exercise.Reps.push(...set.Reps);

      for (let rep of set.Reps) {
        let velocity = getValueByMovementType(rep, "averageSpeed", movementType);
        let power = getValueByMovementType(rep, "averagePower", movementType);
        let force = getValueByMovementType(rep, "averageForce", movementType);
        totalVelocity += velocity;
        totalPower += power;
        totalForce += force;
        numberOfReps++;
      }
    }

    let workoutAverages = {
      name: workouts.length + 1,
      Power: totalPower / numberOfReps,
      Velocity: totalVelocity / numberOfReps,
      Force: totalForce / numberOfReps
    } as { name: number, Power: number, Velocity: number, Force: number };

    includeAverages 
      ? formattedWorkouts.push({exercise, workoutAverages }) 
      : formattedWorkouts.push(exercise);
  }

  return formattedWorkouts;
}

const calculateScoresFromWorkouts = (workouts: any, movementType: string) => {
  let totalSpeed, totalForce, totalPower, totalPop100, totalPop200, totalElasticIndex100, totalElasticIndex200, totalCount, maxSpeed, minSpeed, maxForce, minForce, maxPower, minPower, maxPop100, minPop100, maxPop200, minPop200, maxElasticIndex100, minElasticIndex100, maxElasticIndex200, minElasticIndex200;

  totalSpeed = totalForce = totalPower = totalPop100 = totalPop200 = totalElasticIndex100 = totalElasticIndex200 = totalCount = 0;
  maxSpeed = maxForce = maxPower = maxPop100 = maxPop200 = maxElasticIndex100 = maxElasticIndex200 = -Infinity;
  minSpeed = minForce = minPower = minPop100 = minPop200 = minElasticIndex100 = minElasticIndex200 = Infinity;

  for (let workout of workouts) {
    if (!workout.Reps) continue;
    for (let rep of workout.Reps) {
      let movement = movementType === "Concentric" ? rep.concentricMovement : rep.eccentricMovement;
      if (movement === undefined || movement === null) continue;
      
      let speed = movement.peakSpeed;
      let force = movement.peakForce;
      let power = movement.peakPower;
      let pop100 = movement.pop100;
      let pop200 = movement.pop200;
      let inversePop100 = movement.inversePop100;
      let inversePop200 = movement.inversePop200;
      let elasticIndex100 = (inversePop100 + pop100) / 0.2;
      let elasticIndex200 = (inversePop200 + pop200) / 0.4;

      totalSpeed += speed;
      totalForce += force;
      totalPower += power;
      totalPop100 += pop100;
      totalPop200 += pop200;
      totalElasticIndex100 += elasticIndex100;
      totalElasticIndex200 += elasticIndex200;
      maxSpeed = Math.max(maxSpeed, speed);
      maxForce = Math.max(maxForce, force);
      maxPower = Math.max(maxPower, power);
      maxPop100 = Math.max(maxPop100, pop100);
      maxPop200 = Math.max(maxPop200, pop200);
      maxElasticIndex100 = Math.max(maxElasticIndex100, elasticIndex100);
      maxElasticIndex200 = Math.max(maxElasticIndex200, elasticIndex200);
      minSpeed = Math.min(minSpeed, speed);
      minForce = Math.min(minForce, force);
      minPower = Math.min(minPower, power);
      minPop100 = Math.min(minPop100, pop100);
      minPop200 = Math.min(minPop200, pop200);
      minElasticIndex100 = Math.min(minElasticIndex100, elasticIndex100);
      minElasticIndex200 = Math.min(minElasticIndex200, elasticIndex200);
      totalCount++;
    }
  }

  let scores = { Speed: 0, Force: 0, Power: 0, Pop100: 0, Pop200: 0, EI100: 0, EI200: 0 }

  totalCount !== 0 && (scores = {
    Speed: (totalSpeed / totalCount - minSpeed) / (maxSpeed - minSpeed),
    Force: (totalForce / totalCount - minForce) / (maxForce - minForce),
    Power: (totalPower / totalCount - minPower) / (maxPower - minPower),
    Pop100: (totalPop100 / totalCount - minPop100) / (maxPop100 - minPop100),
    Pop200: (totalPop200 / totalCount - minPop200) / (maxPop200 - minPop200),
    EI100: (totalElasticIndex100 / totalCount - minElasticIndex100) / (maxElasticIndex100 - minElasticIndex100),
    EI200: (totalElasticIndex200 / totalCount - minElasticIndex200) / (maxElasticIndex200 - minElasticIndex200)
  })

  return scores;
}

const formatScoresIntoRadarData = (scores: any) => {
  let radarData = [];
  for (let score in scores) radarData.push({ subject: score, A: scores[score] * 100, B: 0, fullMark: 100 });
  return radarData;
}

const calculateWorkoutAveragesForWorkouts = (workouts: any) => {
  let averages = [] as any[];

  for (const workout of workouts) {
    if (!workout) continue;
    let totalPower = 0;
    let totalVelocity = 0;
    let totalForce = 0;
    let numberOfReps = 0;
    let validWorkout = false;

    for (const set of workout) {
      if (!set.Reps) continue;
      for (const rep of set.Reps) {
        validWorkout = true;
        totalPower += getValueByMovementType(rep, "averagePower", "Concentric");
        totalVelocity += getValueByMovementType(rep, "averageSpeed", "Concentric");
        totalForce += getValueByMovementType(rep, "averageForce", "Concentric");
        numberOfReps++;
      }
    }

    if (!validWorkout) continue;
    let averagePower = totalPower / numberOfReps;
    let averageVelocity = totalVelocity / numberOfReps;
    let averageForce = totalForce / numberOfReps;
    
    averages.push({
      name: averages.length + 1,
      Power: averagePower,
      Velocity: averageVelocity,
      Force: averageForce
    });
  }

  return averages;
}

const calculateSetAveragesForWorkout = (workout: any) => {
  let averages = [] as any[];

  for (const set of workout) {
    if (!set.Reps) continue;

    averages.push({
      name: set.setNumber,
      Power: getMetricAverageFromArray(set.Reps, "concentricMovement", "averagePower"),
      Velocity: getMetricAverageFromArray(set.Reps, "concentricMovement", "averageSpeed"),
      Force: getMetricAverageFromArray(set.Reps, "concentricMovement", "averageForce")
    });
  }

  return averages;
}

const calculateRepsForSet = (set: any) => {
  let chartData = [] as any[];
  let orderedReps = set.Reps.sort((a: any, b: any) => a.repNumber - b.repNumber);
  for (let rep of orderedReps) {
    chartData.push({
      name: rep.repNumber,
      Power: getValueByMovementType(rep, "averagePower", "Concentric"),
      Velocity: getValueByMovementType(rep, "averageSpeed", "Concentric"),
      Force: getValueByMovementType(rep, "averageForce", "Concentric")
    });
  }

  return chartData;
}

const calculateVelocityDiagramValuesForSet = (set: any) => {
  let chartData = [] as any[];
  let orderedReps = set.Reps.sort((a: any, b: any) => a.repNumber - b.repNumber);
  for (let rep of orderedReps) chartData.push(formatRepForVelocityDiagram(rep));
  return chartData;
}

const formatRepForLineChart = (rep: any) => {
  console.log(rep)
  return {
    name: rep.repNumber,
    Power: rep.concentricMovement.averagePower,
    Velocity: rep.concentricMovement.averageSpeed,
    Force: rep.concentricMovement.averageForce
  }
}

const formatRepForVelocityDiagram = (rep: any) => {
  let ePKV = (rep.eccentricMovement.peakVelocityTime - (2 * rep.eccentricMovement.peakVelocityTime)) * 1000;
  let cPKV = rep.concentricMovement.peakVelocityTime * 1000;
  return [
    { x: ePKV, y: rep.eccentricMovement.peakSpeed, label: `Rep ${rep.repNumber}`},
    { x: -200, y: rep.eccentricMovement.inversePop200, label: `Rep ${rep.repNumber}`},
    { x: -100, y: rep.eccentricMovement.inversePop100, label: `Rep ${rep.repNumber}`},
    { x: 0, y: 0, label: `Rep ${rep.repNumber}` },
    { x: 100, y: rep.concentricMovement.pop100, label: `Rep ${rep.repNumber}` },
    { x: 200, y: rep.concentricMovement.pop200, label: `Rep ${rep.repNumber}` },
    { x: cPKV, y: rep.concentricMovement.peakSpeed, label: `Rep ${rep.repNumber}` }
  ]
}

const formatDataForVSD = (data: any, label: string) => {
  let dataIsForReps = data.eccentricMovement ? true : false;

  console.log(data);

  let setAverageEccentricPeakVelocity = dataIsForReps ? data.eccentricMovement.peakVelocityTime : getMetricAverageFromMovement(data, "peakVelocityTime", "eccentricMovement");
  let ePKV = (setAverageEccentricPeakVelocity - (2 * setAverageEccentricPeakVelocity)) * 1000;
  
  let setAverageConcentricPeakVelocity = dataIsForReps ? data.concentricMovement.peakVelocityTime :getMetricAverageFromMovement(data, "peakVelocityTime", "concentricMovement");
  let cPKV = setAverageConcentricPeakVelocity * 1000;
  return [
    { x: ePKV, y: (dataIsForReps ? data.eccentricMovement.peakSpeed : getMetricAverageFromMovement(data, "peakSpeed", "eccentricMovement")), label },
    { x: -200, y: (dataIsForReps ? data.eccentricMovement.inversePop200 : getMetricAverageFromMovement(data, "inversePop200", "eccentricMovement")), label },
    { x: -100, y: (dataIsForReps ? data.eccentricMovement.inversePop100 : getMetricAverageFromMovement(data, "inversePop100", "eccentricMovement")), label },
    { x: 0, y: 0, label },
    { x: 100, y: (dataIsForReps ? data.concentricMovement.pop100 : getMetricAverageFromMovement(data, "pop100", "concentricMovement")), label },
    { x: 200, y: (dataIsForReps ? data.concentricMovement.pop200 : getMetricAverageFromMovement(data, "pop200", "concentricMovement")), label },
    { x: cPKV, y: (dataIsForReps ? data.concentricMovement.peakSpeed : getMetricAverageFromMovement(data, "peakSpeed", "concentricMovement")), label }
  ].sort((a, b) => a.x - b.x);
}

const processDataIntoVSD = (data: any, viewLevel: string) => {
  const vsdValues = [] as any;

  switch (viewLevel) {
    case "Sets":
      data.forEach((set: any, index: number) => {
        vsdValues.push(formatDataForVSD(set.Reps, `Set ${index + 1}`));
      });
      break;
    case "Reps":
      data.forEach((rep: any, index: number) => {
        vsdValues.push(formatDataForVSD(rep, `Rep ${index + 1}`));
      });
      break;
    default:
      break;
  }

  console.log(vsdValues)

  return vsdValues;
}

const formatStatValue = (metricName: string, value: number) => {
  console.log("Formatting value for metric: ", metricName, value);
  let unit = "";
  if (metricName.includes("Power")) unit = "W";
  if (metricName.includes("Velocity")) unit = "m/s";
  if (metricName.includes("Force")) unit = "N";

  let formattedValue = value;
  
  if (!value || value === 0) return "0 " + unit;
  
  value >= 1000 
    ? formattedValue = Math.round(value / 10) * 10
    : formattedValue = Number(value.toFixed(2))

  return (Number.isNaN(formattedValue) ? 0 : formattedValue) + " " + unit;
}

const getMetricAverageFromMovement = (array: any[], metric: string, movement: string) => {
  let average = array.reduce((acc, curr) => acc + (curr.values ? curr.values[metric] : curr[movement][metric]), 0) / array.length;
  console.log("Average for metric: ", metric, average)
  return average;
}

const getMetricAverageFromArray = (array: any[], movementType: string, metric: string) => {
  console.log("Calculating average for metric: ", metric, "from array: ", array);
  return array.reduce((acc, curr) => acc + (curr.values ? curr.values[metric] : curr[movementType][metric]), 0) / array.length;
}

const ucFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export { getValueByMovementType, getSetsForWorkout, calculateScoresFromWorkouts, formatScoresIntoRadarData, calculateWorkoutAveragesForWorkouts, calculateSetAveragesForWorkout, calculateRepsForSet, calculateVelocityDiagramValuesForSet, formatRepForLineChart, formatRepForVelocityDiagram, formatStatValue, getCurrentUserId, getCurrentUserEmail, uploadImage, validateImageFile, handleFileChange, getMetricAverageFromArray, processDataIntoVSD, ucFirst }