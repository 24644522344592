import { fetchUserTeams } from '../firebase/services/teamService';
import { fetchUserProfileById } from '../firebase/services/userService';
import { Team, UserProfile } from '../models'; // Adjust the import path as necessary

async function fetchTeamData(teamId: string | undefined) {
  if (!teamId) return null;
  console.log("Fetching team data", teamId);

  try {
    const teamData = await fetchUserTeams(teamId);
    console.log("Team data", teamData);
    if (!teamData) return null;
    if (!teamData[0].users) return {teamData, athletes: []};

    const userProfilesPromises = teamData[0].users.map(async (userId) => {
      try {
        return await fetchUserProfileById(userId);
      } catch (error) {
        console.warn(`Failed to fetch profile for user ${userId}`, error);
        return null;
      }
    });

    const userProfilesResults = await Promise.all(userProfilesPromises);

    console.log("User profiles", userProfilesResults);

    // Filter out null values and set the team users
    const validUserProfiles: UserProfile[] = userProfilesResults.filter((profile): profile is UserProfile => profile !== null);
    return {teamData, athletes: validUserProfiles};
  } catch (error) {
    console.error("Failed to fetch team data", error);
    return null;
  }
}

export default fetchTeamData;