const numberOfWorkoutsForRadarChart = 12;

const initialLineSettings = [
  { dataKey: "Power", display: true, stroke: "blue"},
  { dataKey: "Force", display: true, stroke: "red" },
  { dataKey: "Velocity", display: true, stroke: "green" }
];

const workoutsForComparison = ["Squat", "Deadlift", "Static Jump", "Counter Move Jump", "Wall Ball", "Step Up Loaded", "Step Up", "Functional Squat"];

const metricsForComparison = {
  "peakPower": "Peak Power",
  "averagePower": "Average Power", 
  "peakSpeed": "Peak Velocity", 
  "averageSpeed": "Average Velocity",
  "peakVelocityTime": "Peak Velocity Time",
  "totalTime": "Total Time",
  "peakMomentum": "Peak Momentum",
  "averageMomentum": "Average Momentum",
  "pop100": "POP 100",
  "pop100Force": "POP 100 Force",
  "pop100Power": "POP 100 Power",
  "pop200": "POP 200",
  "pop200Force": "POP 200 Force",
  "pop200Power": "POP 200 Power",
  "peakForce": "Peak Force",
  "averageForce": "Average Force",
  "totalWork": "Total Work",
  "meanPropulsiveVelocity": "Mean Propulsive Velocity",
  "propulsiveRatio": "Propulsive Ratio",
  "mass": "Mass",
  "reactionTime": "Reaction Time",
  "relativePeakForce": "Relative Peak Force",
  "relativePeakPower": "Relative Peak Power",
  "relativeAverageForce": "Relative Average Force",
  "relativeAveragePower": "Relative Average Power",
};

// List of color codes as long as the number of metrics
const lineChartColorCodes = ["#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5", "#FF5C5C", "#FFE289", "#F5F5F5"];

type WorkoutProtocols = { [key: string]: { imageName: string; movementType: string }};
const workoutProtocols: WorkoutProtocols = {
  "Bench Press": { imageName: "benchPress", movementType: "Upper Body" },
  "Bench Throw": { imageName: "benchThrow", movementType: "Upper Body" },
  "Bench Throw - Static": { imageName: "benchThrowStatic", movementType: "Upper Body" },
  "Between Legs Vertical Throw": { imageName: "betweenLegsVerticalThrow", movementType: "Full Body" },
  "Between Legs Vertical Throw - Static": { imageName: "betweenLegsVerticalStatic", movementType: "Full Body" },
  "Chest Throw": { imageName: "chestThrow", movementType: "Upper Body" },
  "Chest Throw - Static": { imageName: "chestThrowStatic", movementType: "Upper Body" },
  "Countermove Jump": { imageName: "counterMoveJump", movementType: "Lower Body" },
  "Deadlift": { imageName: "deadlift", movementType: "Full Body" },
  "Functional Squat": { imageName: "functionalSquat", movementType: "Lower Body" },
  "Hang Clean": { imageName: "hangClean", movementType: "Full Body" },
  "High Pull": { imageName: "highPull", movementType: "Upper Body" },
  "Power Clean": { imageName: "powerClean", movementType: "Upper Body" },
  "Push Jerk": { imageName: "pushJerk", movementType: "Full Body" },
  "Rotational Throw": { imageName: "rotationalThrow", movementType: "Full Body" },
  "Rotational Throw - Static": { imageName: "rotationalThrowStatic", movementType: "Full Body" },
  "Shoulder Press": { imageName: "shoulderPress", movementType: "Upper Body" },
  "Snatch": { imageName: "snatch", movementType: "Full Body" },
  "Squat": { imageName: "squat", movementType: "Lower Body" },
  "Squat Jump": { imageName: "squatJump", movementType: "Lower Body" },
  "Static Jump": { imageName: "staticJump", movementType: "Lower Body" },
  "Step Up": { imageName: "stepUp", movementType: "Lower Body" },
  "Step Up Loaded": { imageName: "stepUpLoaded", movementType: "Lower Body" },
  "Trap Bar Jump": { imageName: "trapBarJump", movementType: "Full Body" },
  "Trap Bar Jump Countermove": { imageName: "trapBarJump", movementType: "Full Body" },
  "Wall Ball": { imageName: "wallBall", movementType: "Lower Body" },
}

const imageForWorkoutProtocol = (workoutProtocol: string) => {
  console.log("workoutProtocol", workoutProtocol, workoutProtocols[workoutProtocol]);
  return workoutProtocols[workoutProtocol] ? workoutProtocols[workoutProtocol].imageName : "squat";
}

export {
  numberOfWorkoutsForRadarChart,
  initialLineSettings,
  workoutsForComparison,
  metricsForComparison,
  lineChartColorCodes,
  workoutProtocols,
  imageForWorkoutProtocol
};